<template>
  <div>
    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('purchase_configuration.purchase_configuration') }}
            <span class="text-muted pt-2 font-size-sm d-block">
                            {{ $t(('purchase_configuration.purchase_configuration_section')) }}
                        </span>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="card-body p-0">
          <div class="form-group row">
            <div class="col-lg-12 mb-10">
              <b-form-checkbox size="lg" v-model="data.update_product_prices" name="check-button" switch>{{ $t('purchase_configuration.update_product_prices') }}</b-form-checkbox>
            </div>
            <div class="col-lg-12 mb-10">
              <b-form-checkbox size="lg" v-model="data.auto_pay_supplier_invoice" name="check-button" switch>{{ $t('purchase_configuration.auto_pay_supplier_invoice') }}</b-form-checkbox>
            </div>
            <div class="col-lg-12 mb-10">
              <b-form-checkbox size="lg" v-model="data.mark_invoice_default_paid" name="check-button" switch>{{ $t('purchase_configuration.mark_invoice_default_paid') }}</b-form-checkbox>
            </div>
            <div class="col-lg-12 mb-10">
              <b-form-checkbox size="lg" v-model="data.purchase_invoice_manual_status" name="check-button" switch>{{ $t('purchase_configuration.purchase_invoice_manual_status') }}</b-form-checkbox>
            </div>
            <div class="col-lg-12 mb-10">
              <b-form-checkbox size="lg" v-model="data.custom_jornal_description" name="check-button" switch>{{ $t('purchase_configuration.custom_jornal_description') }}</b-form-checkbox>
            </div>
            <div class="col-lg-12 mb-10">
              <b-form-checkbox size="lg" :disabled="true" v-model="data.is_company_has_tax_no" name="check-button" switch>{{ $t('sales_configuration.is_company_has_tax_no') }}</b-form-checkbox>
            </div>

            <div class="col-lg-12 mb-10">
              <b-form-checkbox size="lg" :disabled="!data.is_company_has_tax_no" v-model="data.is_apply_taxes_setting" name="check-button" switch>{{ $t('sales_configuration.is_apply_taxes_setting') }}</b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="card-footer pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6">
              <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
              <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--end::customer-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index-purchase-configuration",
  data() {
    return {
      mainRoute: 'purchases/purchases-settings',
      data: {
        update_product_prices: false,
        auto_pay_supplier_invoice: false,
        mark_invoice_default_paid: false,
        purchase_invoice_manual_status: false,
        custom_jornal_description: false,
        is_company_has_tax_no: false,
        is_apply_taxes_setting: false,
      }
    }
  },
  methods: {
    save() {
      ApiService.post(`${this.mainRoute}`, {
        update_product_prices: this.data.update_product_prices ? 1 : 0,
        auto_pay_supplier_invoice: this.data.auto_pay_supplier_invoice ? 1 : 0,
        mark_invoice_default_paid: this.data.mark_invoice_default_paid ? 1 : 0,
        purchase_invoice_manual_status: this.data.purchase_invoice_manual_status ? 1 : 0,
        custom_jornal_description: this.data.custom_jornal_description ? 1 : 0,
        is_company_has_tax_no: this.data.is_company_has_tax_no ? 1 : 0,
        is_apply_taxes_setting: this.data.is_apply_taxes_setting ? 1 : 0,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    async getData() {
      await ApiService.get(this.mainRoute).then((response) => {
        this.data.update_product_prices = response.data.data.update_product_prices == 1 ? true : false;
        this.data.auto_pay_supplier_invoice = response.data.data.auto_pay_supplier_invoice == 1 ? true : false;
        this.data.mark_invoice_default_paid = response.data.data.mark_invoice_default_paid == 1 ? true : false;
        this.data.purchase_invoice_manual_status = response.data.data.purchase_invoice_manual_status == 1 ? true : false;
        this.data.custom_jornal_description = response.data.data.custom_jornal_description == 1 ? true : false;
        this.data.is_company_has_tax_no = response.data.data.is_company_has_tax_no == 1 ? true : false;
        this.data.is_apply_taxes_setting = response.data.data.is_apply_taxes_setting == 1 ? true : false;
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.purchase_configuration")}]);
    this.getData();

  },
}
</script>

<style scoped>

</style>